import React, { useEffect, useState } from "react";
import axios from "axios";
import Helmet from "react-helmet";
import {
  Jumbotron,
  Container,
  Card,
  Button,
  CardColumns,
} from "react-bootstrap";
import { Flex, Box } from "reflexbox";
import { Tenant } from "@citydelivery/business-logic";
import "./index.scss";

const settings = {
  apiUrl: "https://prod.api.bestelleninbreda.nl",
  contentBaseUrl: "https://citydelivery-images.s3-eu-west-1.amazonaws.com",
};

export default function Home() {
  const [tenants, setTenants] = useState<Tenant[]>([]);

  const getTenants = async () => {
    const { apiUrl } = settings;
    const response = await axios.get<Tenant[]>(`${apiUrl}/tenants`);
    setTenants(
      response.data.filter((data) => data.visibleOnMainWebsite ?? true)
    );
  };

  useEffect(() => {
    getTenants();
  }, []);

  return (
    <div>
      <Helmet>
        <title>Bestellen In Breda</title>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta
          name="description"
          content="Bestel bij lokale ondernemers die hun specialiteiten aanbieden!"
        />
        <meta charSet="utf-8" />
      </Helmet>
      <Container>
        <Flex style={{ padding: 20 }} flexDirection="row" alignItems="center">
          <Box mr={2}>
            <img src="/apple-icon-57x57.png" alt="" />
          </Box>
          <h1>Bestellen In Breda</h1>
        </Flex>
      </Container>
      <img
        style={{ height: 300, width: "100vw", objectFit: "cover" }}
        src="/banner-large.jpg"
        alt=""
      />

      <Jumbotron fluid style={{ backgroundColor: "#cc2841", color: "white" }}>
        <Container>
          <p className="lead">
            Bestel bij jouw favoriete lokale ondernemers in Breda!
          </p>
          <p>Het werkt als volgt:</p>
          <ol>
            <li>Je kiest een restaurant</li>
            <li>Je stelt een bestelling samen op hun pagina</li>
            <li>Je vult je gegevens in en drukt op bestellen</li>
            <li>De bestelling wordt verzonden naar het restaurant</li>
            <li>
              Het restaurant stuurt jou een betaalverzoek en stelt eventueel
              vragen over je bestelling
            </li>
            <li>
              Afhankelijk van jouw bestelling wordt het na betaling bezorgd of
              kun je het afhalen
            </li>
          </ol>
        </Container>
      </Jumbotron>
      <Container>
        <Box my={5}>
          <Box mb={4}>
            <h2>Restaurants</h2>
          </Box>
          <CardColumns>
            {tenants.map((tenant) => (
              <Card>
                <Box style={{ height: 150 }}>
                  <Card.Img
                    variant="top"
                    src={`${settings.contentBaseUrl}/${tenant.tenantId}/${tenant.images.banner}`}
                  />
                </Box>
                <Card.Body>
                  <Card.Title>{tenant.name}</Card.Title>
                  <Card.Text>{tenant.descriptions.subtitle}</Card.Text>
                  <Button
                    variant="primary"
                    onClick={() => {
                      window.location.href = `https://${tenant.tenantId}.bestelleninbreda.nl`;
                    }}
                  >
                    Bekijk
                  </Button>
                </Card.Body>
              </Card>
            ))}
          </CardColumns>
        </Box>
      </Container>
      <Jumbotron fluid style={{ marginBottom: 0 }}>
        <Container>
          <h3>Veelgestelde vragen</h3>
          <h5>Waarom BestelleninBreda.nl?</h5>
          <p>
            BestelleninBreda.nl is gebouwd om ondernemers in staat te stellen om
            op eenvoudige wijze hun producten en diensten aan te bieden. Dit
            kunnen zij via BestelleninBreda.nl volledig gratis doen.
          </p>
          <h5>Wat gebeurt er met mijn gegevens?</h5>
          <p>
            De persoonsgegevens en bestellingen worden alleen naar de
            ondernemers doorgestuurd en verder niet opgeslagen. Een soort
            doorgeefluik dus. Betalingen worden ook niet via BestelleninBreda.nl
            uitgevoerd, de ondernemer zal je hier voor benaderen.
          </p>
        </Container>
      </Jumbotron>
      <Container>
        Heb je vragen of ben je ondernemer en wil je jouw producten aanbieden
        via BestelleninBreda.nl? Neem contact op via{" "}
        <a
          href="mailto:eric+bib@ericpolman.com"
          target="_blank"
          rel="noreferrer"
        >
          eric@ericpolman.com
        </a>
      </Container>
    </div>
  );
}
